import React, { createContext, useContext, useState } from 'react';

const GameContext = createContext();

export function useGameContext() {
  return useContext(GameContext);
}

export const GameProvider = ({ children }) => {
  const [actions, setActions] = useState([]);
  const [actionCounts, setActionCounts] = useState({ A: 0, B: 0, C: 0 });

  const logAction = (action) => {
    if (actionCounts[action.type] < 4) {
      setActions(prev => [...prev, action]);
      setActionCounts(prev => ({ ...prev, [action.type]: prev[action.type] + 1 }));
    }
  };

  const clearActions = () => {
    setActions([]);
    setActionCounts({ A: 0, B: 0, C: 0 });
  };

  return (
    <GameContext.Provider value={{ actions, logAction, clearActions, actionCounts }}>
      {children}
    </GameContext.Provider>
  );
};
