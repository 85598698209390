import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GameProvider } from './contexts/GameContext';
import GameScreen from './views/GameScreen';
import ResultScreen from './views/ResultScreen';

import { ThemeProvider } from '@mui/material/styles';
import { themeOptions} from "./theme";

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
    <Router>
      <GameProvider>
        <Routes>
          <Route path="/" element={<GameScreen />} />
          <Route path="/results" element={<ResultScreen />} />
        </Routes>
      </GameProvider>
    </Router>
    </ThemeProvider>
  );
}

export default App;
