import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';

function Timer({ initialCount, onExpire }) {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    if (count > 0) {
      const timerId = setTimeout(() => setCount(count - 0.1), 100);
      return () => clearTimeout(timerId);
    } else {
      onExpire();
    }
  }, [count, onExpire]);

  return (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress variant="determinate" value={100 * count / initialCount} />
      <Typography variant="h5">{count.toFixed(0)} seconds remaining</Typography>
    </div>
  );
}

export default Timer;
