import React from 'react';
import { Button, Grid } from '@mui/material';
import { useGameContext } from '../contexts/GameContext';

function ButtonPanel({onAction}) {
  const { logAction, actionCounts } = useGameContext();

  const handleButtonPress = action => () => {
    onAction(action);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleButtonPress('A')} disabled={actionCounts.A >= 4}>
          Add A ({4 - actionCounts.A} left)
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleButtonPress('B')} disabled={actionCounts.B >= 4}>
          Add B ({4 - actionCounts.B} left)
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleButtonPress('C')} disabled={actionCounts.C >= 4}>
          Add C ({4 - actionCounts.C} left)
        </Button>
      </Grid>
    </Grid>
  );
}
export default ButtonPanel;
