import axios from 'axios';

const BASE_URL = 'https://daniilboiko--game-runner-game-results.modal.run';

export const submitGameResults = async (actions) => {
  try {
    const response = await axios.post(`${BASE_URL}/`, { actions });
    return response.data;
  } catch (error) {
    console.error('Error submitting game results:', error);
    throw error;
  }
};
