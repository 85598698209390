import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGameContext } from '../contexts/GameContext';
import Timer from '../components/Timer';
import ButtonPanel from '../components/ButtonPanel';
import RulesDialog from '../components/RulesDialog';
import { submitGameResults } from '../api/gameApi';
import Grid from '@mui/material/Grid';

function GameScreen() {
  const { actions, logAction, clearActions } = useGameContext();
  const [timerRunning, setTimerRunning] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (timerRunning) {
      setStartTime(Date.now());
    }
  }, [timerRunning]);

  const startGame = () => {
    clearActions();
    setTimerRunning(true);
    setRulesOpen(false);
  };

  const handleAction = (type) => {
    const currentTime = Date.now();
    const timeElapsed = startTime ? currentTime - startTime : 0; // milliseconds elapsed
    logAction({ type, time: timeElapsed / 1000 }); // log in seconds
  };

  const handleGameEnd = async () => {
    setTimerRunning(false);
    setLoading(true);
    try {
      const result = await submitGameResults(actions);
      navigate('/results', { state: { result } });
    } catch (error) {
      console.error(error);
      // Handle error, maybe show an error message to the user
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Grid container style={{ height: '100vh' }} alignItems="center" justifyContent="center">
        <Grid item>
          <Container style={{textAlign: 'center'}}>
            <CircularProgress />
            <Typography variant="h6" style={{marginTop: '20px'}}>
              Submitting game results...
            </Typography>
          </Container>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container style={{ height: '100vh' }} alignItems="center" justifyContent="center">
      <Grid item>
        <Container maxWidth="sm">
          <RulesDialog open={rulesOpen} onClose={startGame} />
          {timerRunning && (
            <>
              <Timer initialCount={10} onExpire={handleGameEnd} />
              <br />
              <ButtonPanel onAction={handleAction} />
            </>
          )}
        </Container>
      </Grid>
    </Grid>
  );
}

export default GameScreen;
