import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider} from '@mui/material';
import reaction_scheme from './reaction_scheme.png';

function RulesDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rules of the onepot game</DialogTitle>
      <DialogContent>
        <p>The game simulates a chemical reaction. You have 10 seconds to mix compounds A, B, and C.
          Each button press represents adding a reactant to the reaction. Remember the scheme before you proceed.</p>
        <img src={reaction_scheme} style={{maxWidth: "100%"}}/>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">Let's play</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RulesDialog;
