import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Grid, CircularProgress, Paper } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

function ResultScreen() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let timer;
    if (location.state?.result) {
      // Simulate a brief loading period
      timer = setTimeout(() => {
        setResult(location.state.result);
        setLoading(false);
      }, 1000); // Adjust this time as needed
    } else {
      setError("No result data available. Please try playing the game again.");
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [location.state]);

  const compound_labels = ['A', 'B', 'C', 'D', 'E', 'F'];

  const getBarColor = (label) => {
    if (['A', 'B', 'C'].includes(label)) {
      return '#D3D3D3'; // Light gray
    } else if (['D', 'F'].includes(label)) {
      return '#000000'; // Black
    } else if (label === 'E') {
      return '#1E90FF'; // Dodger Blue
    }
  };

  if (loading) {
    return (
      <Grid container style={{height: '100vh'}} alignItems="center" justifyContent="center">
        <Grid item>
          <Container style={{textAlign: 'center'}}>
            <CircularProgress />
            <Typography variant="h6" style={{marginTop: '20px'}}>
              Processing simulation results...
            </Typography>
          </Container>
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container style={{height: '100vh'}} alignItems="center" justifyContent="center">
        <Grid item>
          <Paper elevation={3} style={{padding: '20px', textAlign: 'center'}}>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container style={{height: '100vh'}} alignItems="center" justifyContent="center">
      <Grid item>
        <Container style={{textAlign: 'center'}}>
          <Typography variant="h4" gutterBottom>Game Results</Typography>
          <p>Your yield of <b>E</b> is {result.simulation_result.final_composition['E'].toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 })}.</p>
          <p>Our yield of <b>E</b> is 47.96%</p>
          <br></br>
          <Paper elevation={1} style={{padding: '20px', marginTop: '20px'}}>
            <p style={{margin: "0px"}}>Composition of reaction mixture</p>
            <BarChart
              xAxis={[{
                scaleType: 'band',
                data: compound_labels,
                colorMap: {
                  type: 'ordinal',
                  values: compound_labels,
                  colors: compound_labels.map((label) => getBarColor(label)),
                }
              }]}
              series={[{
                data: compound_labels.map((label) => result.simulation_result.final_composition[label] || 0),
                color: ({ key }) => getBarColor(key)
              }]}
              width={500}
              height={300}
            />
          </Paper>
          {result.feedback && (
            <Paper elevation={3} style={{padding: '20px'}}>
              <Typography variant="h6" gutterBottom>Feedback:</Typography>
              <Typography>{result.feedback}</Typography>
            </Paper>
          )}
        </Container>
      </Grid>
    </Grid>
  );
}

export default ResultScreen;
