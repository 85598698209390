import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#5e35b1',
    },
    background: {
      default: '#f5f5f5',
      paper: '#fafafa',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
